@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Urbanist";
    src: url("./fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
  }
}

body {
  font-family: 'Urbanist', 'Montserrat';
}
